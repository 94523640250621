import React, { } from 'react'
import { getFirebaseConfig } from '../lib'
import { initializeApp } from 'firebase/app'
import { getFunctions, httpsCallable } from 'firebase/functions'

const app = initializeApp(getFirebaseConfig())

export default class DBuyFormCls extends React.Component {
  
  constructor(props) {
    super(props)
    // console.log('*** dbuy props', JSON.stringify(props.props))  
    this.state = {
      init: true,
      txnToken: '',
      synchpartnerid: '',
      firstName: props.props.firstName,
      lastName: props.props.lastName,
      refId: props.props.refId,
      accountId: props.props.merchantId,
      city: props.props.city,
      zip: props.props.zip,
      state: props.props.state,
      address: props.props.address,
      amount: props.props.amount,
      phone: props.props.phone,
      isFinal: false,
      errorMsg: false,
      authCode: '',
      authToken: '',
      lendResult: props.props.lendResult,
      lendApplyUrl: props.props.lendApplyUrl,
      key: props.props.synchkey,
    }
    this.setState = this.setState.bind(this)
    this.showDBuy = this.showDBuy.bind(this)
    this.fJSON = this.fJSON.bind(this)
    this.finalize = this.finalize.bind(this)
    this.handlePayment = this.handlePayment.bind(this)
    this.midSelected = this.midSelected.bind(this)
    
    this.setState({ firstName: this.state.firstName })
    this.setState({ accountId: this.state.accountId })
  }

  callback = async (event) => {
    // console.log('callback: ', JSON.stringify(event), event.data)
    if ((typeof event.data == 'string' || typeof event.data == 'object') 
      && (event.data == 'Close Model' || event.data == 'Return To Merchant Shipping' || event.data == 'Close' 
      || event.data.action == 'setPayCloseModal')) 
      { 
        const data = {
          authToken: this.state.authToken,
          amount:  this.state.amount,
          partnerId: this.state.synchpartnerid,
          accountId: this.state.accountId,
          refId: this.state.refId,
          lastName: this.state.lastName,
          firstName: this.state.firstName,
          txnToken: this.state.txnToken,
          address: this.state.address, 
          city: this.state.city, 
          state: this.state.state, 
          zip: this.state.zip,
          phone: this.state.phone,
          mid: this.state.selectedMid,
          key: this.state.key,
        }
        // console.log('SYNCHRONY UNIFI MODAL CLOSED', this.state.synchpartnerid, this.state.amount, event, this.state.txnToken, this.state.accountId) 
        console.log('*** modal closed - getting results', data)
        // const functions = getFunctions(app)
        // const finalizeSynchTxn = httpsCallable(functions, 'onCall-finalizeSynchTxn')
        // let finalResp = await finalizeSynchTxn(data)
        // console.log('*** finalResp', JSON.stringify(finalResp))
        
        //send data back to parent
        this.props.dBuyFinished(data, this.state.fullPromos)
        

        // this.setState({ errorMsg: finalResp.data.error })
        // if (finalResp.data.authCode) {
        //   this.setState({ isFinal: true })
        //  this.setState({ authCode: finalResp.data.authCode })
        // }

    }
  }

  async componentDidMount() {
    console.log('*** componentDidmount')
    const functions = getFunctions(app)
    const getSynchTxnToken = await httpsCallable(functions, 'onCall-getSynchTxnToken')
    const data = {
      accountId: this.state.accountId,
      amount: this.state.amount,
    }
    let tokenResp = await getSynchTxnToken(data)
    // console.log('getSynchTxnToken', JSON.stringify(tokenResp))
    // console.log('seledctedMid', this.state.selectedMid)

    let authToken = tokenResp.data.authToken
    let synchMid = tokenResp.data.synchMid
    let synchMid2 = tokenResp.data.synchMid2
    let synchMid3 = tokenResp.data.synchMid3
    let synchMidLabel = tokenResp.data.synchMidLabel
    let synchMid2Label = tokenResp.data.synchMid2Label
    let synchMid3Label = tokenResp.data.synchMid3Label
    
    if (!synchMid2 && !synchMid3) {
      this.setState({ selectedMid: synchMid })
    }

    let txnToken = tokenResp.data.txnToken // 'PI2092063312510191802c2a09'
    let promos = tokenResp.data.promos

    let synchpartnerid = tokenResp.data.synchpartnerid // 'PI20920633'
    let accountId = this.state.accountId
    let firstName = this.state.firstName
    let lastName = this.state.lastName
    // let refId = this.state.refId
    let address = this.state.address
    let city = this.state.city
    let state = this.state.state
    let zip = this.state.zip
    let amount = this.state.amount
    console.log(authToken, accountId, lastName, firstName, promos)

    //generate unique transid - but cannot be more than 20 characters
    let txnId = crypto.randomUUID()
    txnId = txnId.substring(txnId.length-20)
    console.log('promos', promos)
    const promoMap = new Map(Object.entries(promos))
    const offers = [...promoMap.keys()]
    const fullPromos = promoMap
    this.setState({ init: true })
    this.setState({ txnToken: txnToken })
    this.setState({ txnId: txnId })
    this.setState({ synchpartnerid: synchpartnerid })
    this.setState({ address: address })
    this.setState({ city: city })
    this.setState({ state: state })
    this.setState({ zip: zip })
    this.setState({ amount: amount })
    this.setState({ authToken: authToken })
    this.setState({ synchMid: synchMid })
    this.setState({ synchMid2: synchMid2 })
    this.setState({ synchMid3: synchMid3 })
    this.setState({ synchMidLabel: synchMidLabel })
    this.setState({ synchMid2Label: synchMid2Label })
    this.setState({ synchMid3Label: synchMid3Label })
    this.setState({ offerNumber1: offers })
    this.setState({ fullPromos: fullPromos })
    // console.log('synchMid', synchMid)

    // Initialize the syfWidgetObject
    const syfWidgetObject = {}
    syfWidgetObject.syfPartnerId = synchpartnerid 
    syfWidgetObject.flowType = 'PDP'
    syfWidgetObject.childSyfMerchantNumber = synchMid 
    syfWidgetObject.promoOverRideFlag = "Y"
  
    // Load the external script
    const script = document.createElement('script')
    script.id = 'syfMPPScript'
    script.type = 'text/javascript'
    script.src = 'https://spdpone.syfpos.com/mpp/UniFi.js'
    script.async = true
    document.body.appendChild(script)

    window.addEventListener('message', this.callback)

  }

  render() {
    
    const { 
     txnToken, 
     synchpartnerid,
     txnId,
     address,
     city,
     state,
     zip,
     amount,
     synchMid,
     synchMid2,
     synchMid3,
     synchMidLabel,
     synchMid2Label,
     synchMid3Label,
     offerNumber1,
     selectedMid,
    } = this.state
    
    // console.log('txnId', txnId)
    // console.log('synchpartnerid', synchpartnerid)
    // console.log('*** txnToken', txnToken)
    // console.log('data', isFinal, authCode, errorMsg, synchMid)

    if (!synchMid) {
      return (
        <div>
          <div class="cfpspinnertext" >Loading Synchrony ... <div className="cfpspinner"></div></div>
        </div>
      )
    }

    
    if (synchMid) {
      return (
          <div>
            <hr style={{ height: '10px', visibility: 'hidden' }} />
              <div class="box">
                <b>
                  <h7 >

                    <div style={{ color: 'white' }} dangerouslySetInnerHTML={{ __html: this.state.lendResult }} />

                    { this.state.lendApplyUrl && (
                            <span style={{ color: 'white' }}>&nbsp;&nbsp; Click <a class="mylink" target="_blank" href={this.state.lendApplyUrl}>here</a> to apply!
                            </span>
                            
                    )}

                    { !this.state.lendApplyUrl && (     
                      <div>
                        <label style={{ color: 'white' }}><i><b>OR</b></i></label>

                        { (synchMid2 || synchMid3) && (
                           
                           <div style={{ color: 'white' }}>To make a purchase with Synchrony, please select which card program you would like to use:&nbsp;
                            <select style={{ borderRadius: '4px', outline: 'none', backgroundColor: 'white', borderColor: 'white' }}value={this.state.selectedMid} onChange={this.midSelected} name="mids" id="mids">
                              <option value="" >-- Select a Program --</option>
                              <option value={synchMid}>{synchMidLabel}</option>
                              <option value={synchMid2}>{synchMid2Label}</option>
                              <option value={synchMid3}>{synchMid3Label}</option>
                            </select>
                          </div>
                          
                        )}

                        <form name="dbuyform" id="dbuyform">
                          
                          <div>
                            <label hidden htmlFor="transactionToken">Token to use:</label>
                            <input id="transactionToken" type="hidden" name="transactionToken" defaultValue={txnToken}/>
                          </div>
                          
                          <div>
                            <label hidden htmlFor="syfPartnerId">Partner ID:</label>
                            <input id="syfPartnerId" type="hidden" name="syfPartnerId" defaultValue={synchpartnerid} />
                          </div>
                          <div>
                            <label hidden htmlFor="clientTransId">Client Transaction ID:</label>
                            <input id="clientTransId" type="hidden" name="clientTransId" defaultValue={txnId}/>
                          </div>
                          <div>
                            <label hidden htmlFor="processInd">Process Indicator:</label>
                            <input id="processInd" type="hidden" name="processInd" defaultValue="3" />
                          </div>
                          <div>
                            <label hidden htmlFor="custAddress1">Address1:</label>
                            <input id="custAddress1" type="hidden" name="custAddress1" defaultValue={address} />
                          </div>
                          <div>
                            <label hidden htmlFor="custAddress2">Address2:</label>
                            <input id="custAddress2" type="hidden" name="custAddress2" />
                          </div>
                          <div>
                            <label hidden htmlFor="custCity">City:</label>
                            <input id="custCity" type="hidden" name="custCity" defaultValu={city} />
                          </div>
                          <div>
                            <label hidden htmlFor="custState">State:</label>
                            <input id="custState" type="hidden" name="custState" defaultValue={state} />
                          </div>
                          <div>
                            <label hidden htmlFor="custZipCode">ZIP Code:</label>
                            <input id="custZipCode" type="hidden" name="custZipCode" defaultValue={zip} />
                          </div>
                          <div>
                            <label hidden htmlFor="transAmount1">Transaction Amount:</label>
                            <input id="transAmount1" type="hidden" name="transAmount1" defaultValue={amount} />
                          </div>
                          <div>
                            <label hidden htmlFor="transAmount1">promoOverRideFlag</label>
                            <input id="promoOverRideFlag" type="hidden" name="promoOverRideFlag" defaultValue="Y" />
                          </div>
                          
                          <div><label hidden>Tran Codes: </label><input id="offerNumber1" type="hidden" name="offerNumber1" value={offerNumber1} /></div>
                          <div>
                            <input type="hidden" 
                              id="childSyfMerchantNumber"
                              name="childSyfMerchantNumber"
                              defaultValue={selectedMid}
                            />
                          </div>
                          
                        </form>
                        
                        { (selectedMid) && (
                          <button className="btnwide btn btn-primary mt-1 mb-1" type="button" id="checkouthtml" onClick={this.handlePayment}>
                            Complete Payment With Synchrony Card
                          </button>
                        )}
                      </div>  
                    )}
                </h7>
              </b>
            </div>
          </div>
        )
      }
  }
  
  async finalize() {
  
  }

  fJSON() {
    // console.log('in fJSON', this.state.synchpartnerid)
    let JSONObject = {}
    JSONObject.syfPartnerId = this.state.synchpartnerid
    JSONObject.transactionToken = this.state.txnToken
    JSONObject.processInd = '3'
    JSONObject.clientTransId = this.state.txnId
    JSONObject.custAddress1 = this.state.address
    JSONObject.custAddress2 = ''
    JSONObject.custCity = this.state.city
    JSONObject.custState = this.state.state
    JSONObject.custZipCode = this.state.zip
    JSONObject.transAmount1 = this.state.amount
    JSONObject.offerNumber1 = this.state.offerNumber1
    JSONObject.childSyfMerchantNumber = this.state.synchMid
    JSONObject.promoOverRideFlag = "Y"
    
    // console.log('fJSON', JSON.stringify(JSONObject))
    return JSONObject
  }

  showDBuy() {
    this.setState({ init: false })
  }

  midSelected = (event) => {
    if (event.target.value) this.setState({ selectedMid: event.target.value })
  }

  handlePayment () {
    // Assuming syfMPP is defined in the external script
    if (window.syfMPP) {
      this.props.dBuyStarted()
      // console.log('fields', document.getElementById('dbuyform'))
      window.syfMPP.calldBuyProcess(document.getElementById('dbuyform'), null)
      // window.syfMPP.calldBuyProcess(null, this.fJSON())
    } else {
      console.error('syfMPP is not loaded')
    }
  }
}

// export default DBuyForm